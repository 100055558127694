$border-radius: 4px;
$font-size: 16px;

$viewport-height: var(--tg-viewport-height);
$theme-bg-color: var(--tg-theme-bg-color, rgb(36, 34, 49));
$text-color: var(--tg-theme-text-color, rgb(245, 243, 255));
$link-color: var(--tg-theme-link-color, darkslateblue);
$button-color: var(--tg-theme-button-color, darkslateblue);
$button-text-color: var(--tg-theme-button-text-color, #fff);
$secondary-bg-color: var(--tg-theme-secondary-bg-color);

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	outline: none;
	border: none;
	background: none;
}
body {
	overflow: hidden;
	font-family:
		system-ui,
		-apple-system,
		BlinkMacSystemFont,
		'Segoe UI',
		Roboto,
		Oxygen,
		Ubuntu,
		Cantarell,
		'Open Sans',
		'Helvetica Neue',
		sans-serif;
	height: 100vh;
	width: 100%;
	background-color: $theme-bg-color;
	color: $text-color;
	font-size: $font-size;
}
html,
body {
	-webkit-user-select: none;
	-webkit-touch-callout: none;
}

*[contenteditable] {
	-webkit-user-select: auto !important;
}
::-webkit-calendar-picker-indicator {
	background-color: $button-color;
	cursor: pointer;
}
#root {
	padding: 15px;
	overflow-y: auto;
	height: 100%;
	padding: 20px 20px 30px 20px;
	max-width: 560px;
	margin: 0 auto;
}

.form-wrapper {
	margin-top: 20px;
}

#form {
	&,
	.dinamic-elements,
	& > label {
		display: flex;
		flex-direction: column;
		gap: 15px;
		width: 100%;

		.wrapper {
			display: flex;
			gap: 10px;
			align-items: center;
		}

		.field {
			color: color-mix(in srgb, $button-color 70%, #fff 40%);
			border-radius: $border-radius;
			padding: 12px 8px;
			pointer-events: all;
			flex: 1;
			background: $theme-bg-color;
			filter: brightness(85%);
			width: 100%;
			max-height: 32px;

			&.area {
				min-height: 180px;
				max-height: max-content;
				pointer-events: all;
				display: flex;
				cursor: text;
				text-align: left;
				align-items: flex-start;
				justify-content: flex-start;
				flex-direction: column;
				position: relative;
				resize: vertical;
				flex: auto;
			}
		}
	}
	label {
		gap: 10px;
		pointer-events: none;
		width: 100%;
	}

	.fields-date {
		&__checkbox {
			display: flex;
			align-items: center;
			gap: 20px;

			input {
				border-radius: $border-radius;
				width: 20px;
				height: 20px;
				display: flex;
				border: 2px #a39ebe78 solid;
				margin: 10px 0;
				cursor: pointer;
				background-color: $button-color;
			}
		}

		&__wrapper {
			display: flex;
			gap: 20px;
			transition: 0.2s;
			height: 40px;
			align-items: center;
			margin-top: 10px;

			& input {
				width: 100%;
			}

			&.hide {
				height: 0;
			}

			label {
				width: 100%;
			}
		}
	}
}

.btn {
	cursor: pointer;
	padding: 12px;
	border-radius: $border-radius;
	transition: 0.2s;
	border: 2px solid $button-color;
	color: $button-color;
	pointer-events: all;

	&:disabled {
		cursor: not-allowed;
		opacity: 0.5;
	}

	&.select {
		background-color: $button-color;
		color: $button-text-color;
		border-color: $button-color;
	}

	&-submit {
		background-color: $button-color;

		margin-top: 20px;
		color: $button-text-color;
	}
}

.hide {
	opacity: 0;
	visibility: hidden;
}

.people-tag {
	display: flex;
	gap: 3px;
	font-size: 13px;
}

.add-field {
	width: 30px;
	margin-left: auto;
	height: 30px;
	display: flex;
	padding: 0;
	align-items: center;
	justify-content: center;
	font-size: 20px;
	max-height: 32px;
}

.app-form__top-buttons {
	display: flex;
	gap: 10px;
	margin-bottom: 20px;

	& .btn {
		flex: 1;
		padding: 6px;
	}
}
